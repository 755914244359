<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <informacoes-basicas
          :titulo.sync="titulo"
          :descricao.sync="descricao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <informacoes-entidade
          :empresa.sync="empresa"
          :bdgdVersao.sync="bdgdVersao"
          :entidade.sync="entidade"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <configuracoes-extras
          :backupEntity.sync="backupEntity"
          :tipoComando.sync="tipoComando"
        />
      </v-col>
    </v-row>
    <v-row v-if="entidade && tipoComando === 'UPDATE'">
      <v-col cols="12">
        <campos-atualizacao
          ref="refCamposAtualizacao"
          :campos="campos"
          :baseDados="entidade"
          @loading="loading = $event"
        />
      </v-col>
    </v-row>
    <v-row v-if="entidade">
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          :campos="campos"
          :baseDados="entidade"
          @loading="loading = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="salvar()"
          :loading="loading"
          :disabled="!formularioValido"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AtualizacoesBdgdViaSqlService from '@/services/AtualizacoesBdgdViaSqlService';
import BasesDadosService from '@/services/BasesDadosService';
import CompanyService from '@/services/CompanyService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [camposFiltrosMixins],
  components: {
    InformacoesBasicas: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/sql/InformacoesBasicas'
      ),
    InformacoesEntidade: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/sql/InformacoesEntidade'
      ),
    ConfiguracoesExtras: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/sql/ConfiguracoesExtras'
      ),
    CamposAtualizacao: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/sql/CamposAtualizacao'
      ),
    CamposFiltros: () => import('@/components/relatorios/CamposFiltros')
  },
  data: () => ({
    titulo: '',
    descricao: '',
    empresa: { id: null },
    bdgdVersao: { id: null },
    entidade: '',
    backupEntity: false,
    tipoComando: 'UPDATE',
    campos: [],
    loading: false
  }),
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    formularioValido() {
      return (
        this.titulo &&
        this.empresa &&
        this.empresa.id &&
        this.bdgdVersao &&
        this.bdgdVersao.id &&
        this.entidade &&
        this.campos &&
        this.campos.length > 0
      );
    }
  },
  mounted() {
    this.empresa = this.userLoggedCompany;
  },
  methods: {
    setEmpresa(empresaId) {
      CompanyService.getCompaniesByLoggedUser()
        .then((companies) => {
          this.empresa = companies.find((company) => company.id === empresaId);
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar as empresas liberadas para acesso do usuário.',
            '',
            { position: 'topRight' }
          );
        });
    },
    baseDeDadosUpdated(entidade) {
      const tabela = entidade.toLowerCase();
      BasesDadosService.getBasesDadosCampos(tabela)
        .then((campos) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.campos = campos.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar campos da entidade selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    salvar() {
      const filtros = this.$refs.refCamposFiltros.exportCampos();
      const camposAtualizacao =
        this.tipoComando === 'UPDATE'
          ? this.$refs.refCamposAtualizacao.exportCampos()
          : [];
      if (filtros === 'invalido' || camposAtualizacao === 'invalido') return;
      if (
        this.tipoComando === 'UPDATE' &&
        camposAtualizacao[0] &&
        camposAtualizacao[0].campo === ''
      ) {
        this.$toast.warning(
          'Selecione ao menos um campo para a atualização.',
          '',
          { position: 'topRight' }
        );
        return;
      }
      if (
        !confirm(
          'Tem certeza que deseja executar a atualização para a entidade selecionada?'
        )
      ) {
        return;
      }
      let data = {
        titulo: this.titulo,
        descricao: this.descricao,
        entidade: this.entidade,
        tipo_comando: this.tipoComando,
        bdgd_versao_id: this.bdgdVersao.id,
        efetuar_backup_entidade: this.backupEntity,
        company_id: this.empresa.id,
        filtros,
        campos_atualizacao: camposAtualizacao
      };
      AtualizacoesBdgdViaSqlService.save(data)
        .then(() => {
          this.$toast.success('Ajuste de BDGD salvo sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar o ajuste de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    entidade(newValue) {
      this.baseDeDadosUpdated(newValue);
    }
  }
};
</script>
